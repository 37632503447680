/* ----------------------------------------------------------------------------
sound player
---------------------------------------------------------------------------- */
export default class SoundPlayer extends HTMLElement {

  playable() {
    this.toggle.addEventListener('click', (e) => {
      this.update();
    });
    this.fan.addEventListener('click', (e) => {
      this.update();
    });
  }

  update() {
    const pressed = this.toggle.getAttribute('aria-pressed') === 'true';
    this.toggle.setAttribute('aria-pressed', String(!pressed));
    this.toggle.classList.toggle('--active');
    this.fan.classList.toggle('--active');
    this.blades.classList.toggle('--active');
    if (this.audio.paused) {
      this.audio.play();
      this.toggle.setAttribute('aria-label', 'pause');
    } else {
      this.audio.pause();
      this.toggle.setAttribute('aria-label', 'play');
    }
  }

  loop() {
    this.audio.addEventListener("timeupdate", () => {
      if (this.audio.currentTime > this.audio.duration - 1) {
        this.audio.currentTime = 1;
        this.audio.play();
      }
    });
  }

  connectedCallback() {
    this.fan = this.querySelector('figure')
    this.blades = this.querySelector('.blades')
    this.toggle = this.querySelector('button');
    this.audio = this.querySelector('audio');
    this.playable();
    this.loop();
  }
}

customElements.define('sound-player', SoundPlayer);
